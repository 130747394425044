import { createRouter, createWebHistory } from 'vue-router';
import {
  redirectIfAuthenticated,
  isAuthenticated,
  allow,
} from '@/helpers/guards';

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/auth.vue'),
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/login.vue'),
        beforeEnter: redirectIfAuthenticated('dashboard'),
        meta: {
          title: 'Login',
          store: false,
        },
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('@/pages/register.vue'),
        meta: {
          title: 'Register',
          store: false,
        },
      },
      {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('@/pages/reset-password.vue'),
        meta: {
          title: 'Reset Password',
          store: false,
        },
      },
    ],
  },
  {
    component: () => import('@/layouts/default.vue'),
    beforeEnter: isAuthenticated,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/pages/dashboard.vue'),
        meta: {
          title: 'Dashboard',
          store: true,
        },
      },
      {
        path: '/invoices/:id',
        name: 'invoice-detail',
        component: () => import('@/pages/invoice-detail.vue'),
        //beforeEnter: allow('invoices', 'create|edit'),
        meta: {
          title: 'Invoice Detail',
          store: true,
        },
      },
      {
        path: '/invoices-by-jobs',
        name: 'invoices-by-jobs',
        component: () => import('@/pages/invoices-by-jobs.vue'),
        beforeEnter: allow('invoices-by-jobs', 'view'),
        meta: {
          title: 'Invoices by Jobs',
          store: true,
        },
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: () => import('@/pages/notifications.vue'),
        beforeEnter: allow('notifications', 'view'),
        meta: {
          title: 'Notifications',
          store: true,
        },
      },
      {
        path: '/users',
        name: 'users',
        component: () => import('@/pages/users.vue'),
        beforeEnter: allow('users', '*'),
        meta: {
          title: 'Users',
          store: true,
        },
      },
      {
        path: '/presets',
        name: 'presets',
        component: () => import('@/pages/presets.vue'),
        beforeEnter: allow('presets', 'view'),
        meta: {
          title: 'Presets',
          store: true,
        },
      },
      {
        path: '/role-management',
        name: 'role-management',
        component: () => import('@/pages/permissions.vue'),
        beforeEnter: allow('permissions', 'edit'),
        meta: {
          title: 'Role Management',
          store: true,
        },
      },
      {
        path: '/account',
        name: 'account',
        component: () => import('@/pages/account.vue'),
        meta: {
          title: 'Account',
          store: true,
        },
      },
      {
        path: '/auth-tokens',
        name: 'auth-tokens',
        component: () => import('@/pages/auth-tokens.vue'),
        beforeEnter: allow('auth:tokens', '*'),
        meta: {
          title: 'API Tokens',
          store: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to) => {
  if(to.redirectedFrom){
    if(to.redirectedFrom.name == 'invoice-detail'){
      const name = to.redirectedFrom.name;
      const params = to.redirectedFrom.params;
      localStorage.setItem('page', JSON.stringify({ name, params }));
    }
    else{
      if (to.meta.store) {
        const { name, params } = to;
        localStorage.setItem('page', JSON.stringify({ name, params }));
      }
    }
  }  
  if (to.meta.store) {
    const { name, params } = to;
    localStorage.setItem('page', JSON.stringify({ name, params }));
  }
});

export default router;
