<template>
  <div class="h-full">
    <router-view />
    <Toast />
  </div>
</template>

<script>
export default {
  watch: {
    $route(to) {
      document.title = to.meta.title || process.env.VUE_APP_NAME;
    },
  },
};
</script>
