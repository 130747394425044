<template>
  <svg
    width="20"
    height="23"
    viewBox="0 0 20 23"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      d="M6.25 8.25C6.66421 8.25 7 8.58579 7 9V18C7 18.4142 6.66421 18.75 6.25 18.75C5.83579 18.75 5.5 18.4142 5.5 18V9C5.5 8.58579 5.83579 8.25 6.25 8.25Z"
    />
    <path
      d="M10 8.25C10.4142 8.25 10.75 8.58579 10.75 9V18C10.75 18.4142 10.4142 18.75 10 18.75C9.58579 18.75 9.25 18.4142 9.25 18V9C9.25 8.58579 9.58579 8.25 10 8.25Z"
    />
    <path
      d="M14.5 9C14.5 8.58579 14.1642 8.25 13.75 8.25C13.3358 8.25 13 8.58579 13 9V18C13 18.4142 13.3358 18.75 13.75 18.75C14.1642 18.75 14.5 18.4142 14.5 18V9Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.75 4.5C19.75 5.32843 19.0784 6 18.25 6H17.5V19.5C17.5 21.1569 16.1569 22.5 14.5 22.5H5.5C3.84315 22.5 2.5 21.1569 2.5 19.5V6H1.75C0.921573 6 0.25 5.32843 0.25 4.5V3C0.25 2.17157 0.921573 1.5 1.75 1.5H7C7 0.671573 7.67157 0 8.5 0H11.5C12.3284 0 13 0.671573 13 1.5H18.25C19.0784 1.5 19.75 2.17157 19.75 3V4.5ZM4.17705 6L4 6.08853V19.5C4 20.3284 4.67157 21 5.5 21H14.5C15.3284 21 16 20.3284 16 19.5V6.08853L15.8229 6H4.17705ZM1.75 4.5V3H18.25V4.5H1.75Z"
    />
  </svg>
</template>
