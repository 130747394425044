<template>
  <Modal :id="id" :title="title">
    <template #default="{ close, context }">
      <p class="mt-2 mb-6 text-center font-medium">
        <slot>
          {{ context.options.body }}
        </slot>
      </p>
      <div class="text-center">
        <Button
          class="mr-2 w-1/3"
          @click="context.options.confirm.action({ close })"
          >{{ context.options.confirm.text }}</Button
        >
        <Button class="ml-2 w-1/3" variant="secondary" @click="close"
          >Cancel</Button
        >
      </div>
    </template>
  </Modal>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: () => '',
      required: true,
    },
    title: {
      type: String,
      default: () => 'Confirm',
    },
  },
};
</script>
