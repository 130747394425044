import Form from '@/components/forms/base/Form.vue';
import Field from '@/components/forms/base/Field.vue';
import RoleSelect from '@/components/forms/base/RoleSelect.vue';
import Checkbox from '@/components/forms/base/Checkbox.vue';
import CheckboxButton from '@/components/forms/base/CheckboxButton.vue';

export default {
  install(app) {
    app.component('Form', Form);
    app.component('RoleSelect', RoleSelect);
    app.component('Field', Field);
    app.component('Checkbox', Checkbox);
    app.component('CheckboxButton', CheckboxButton);
  },
};
