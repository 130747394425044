import axios from 'axios';
import store from '../store';
import router from '../router';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  (next) => {
    return Promise.resolve(next);
  },
  (error) => {
    if (error.response) {
      const { status, data } = error.response;
      const { instance } = data;
      if (status === 401 && !instance.includes('update-password')) {
        store.dispatch('user/logoutWithoutAction');
      }
      if (status === 403) {
        router.push({ name: 'dashboard' });
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
