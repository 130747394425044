<template>
  <span class="badge" :class="[`badge-${variant}`]">
    <slot>
      {{ label }}
    </slot>
  </span>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: () => '',
    },
    variant: {
      type: String,
      default: () => 'primary',
    },
  },
};
</script>

<style lang="postcss" scoped>
.badge {
  @apply inline-block px-4 py-1 rounded-3xl font-poppins font-medium whitespace-nowrap;
}
.badge-primary {
  @apply bg-yellow-500 text-white;
}
.badge-success {
  @apply bg-green-600 text-white;
}
.badge-danger {
  @apply bg-red-200 text-red-600;
}
.badge-warning {
  @apply bg-yellow-200 text-yellow-700;
}
.badge-gray {
  @apply bg-gray-400 text-white;
}
.badge-blue {
  @apply bg-blue-500 text-white;
}
.badge-orange {
  @apply bg-orange-500 text-white;
}
.badge-brown {
  @apply bg-yellow-900 text-white;
}
</style>
