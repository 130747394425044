<template>
  <svg
    width="13"
    height="11"
    viewBox="0 0 13 11"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.25 5.5C0.25 5.06853 0.599778 4.71875 1.03125 4.71875L10.0826 4.71875L6.72882 1.36493C6.42372 1.05983 6.42372 0.56517 6.72882 0.260073C7.03392 -0.0450249 7.52858 -0.0450249 7.83368 0.260073L12.5212 4.94757C12.8263 5.25267 12.8263 5.74733 12.5212 6.05243L7.83368 10.7399C7.52858 11.045 7.03392 11.045 6.72882 10.7399C6.42372 10.4348 6.42372 9.94017 6.72882 9.63507L10.0826 6.28125L1.03125 6.28125C0.599778 6.28125 0.25 5.93147 0.25 5.5Z"
    />
  </svg>
</template>
