<template>
  <svg
    width="13"
    height="15"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      d="M6.53298 1.8916V7.5003M6.53298 7.5003V13.109M6.53298 7.5003H11.7994M6.53298 7.5003H1.2666"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
