<template>
  <svg
    width="78"
    height="76"
    viewBox="0 0 78 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      d="M26.5 38.9999L34.8333 47.3333L51.5 30.6666M74.9083 13.9333C61.7338 14.6329 48.8197 10.0775 39 1.2666C29.1803 10.0775 16.2663 14.6329 3.09168 13.9333C2.0314 18.0379 1.49657 22.2606 1.50002 26.4999C1.50002 49.7958 17.4333 69.3749 39 74.9249C60.5667 69.3749 76.5 49.7999 76.5 26.4999C76.5 22.1583 75.9458 17.9499 74.9083 13.9333Z"
      stroke-width="6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
