import store from '../store';
import { useAllow } from '@/helpers/composables';

export const redirectIfAuthenticated = (name) => (to, from, next) => {
  const isAuthenticated = store.getters['user/isAuthenticated'];
  if (isAuthenticated) {
    return next({ name });
  }
  next();
};

export const isAuthenticated = (to, from, next) => {
  const isAuthenticated = store.getters['user/isAuthenticated'];
  if (isAuthenticated) {
    next();
  } else {
    next({ name: 'login' });
  }
};

export const allow = (resource, action) => (to, from, next) => {
  const { permissions } = store.state.user;
  const permission = useAllow(permissions, resource, action);
  if (!permission) {
    next({ name: 'dashboard' });
  }
  return next();
};
