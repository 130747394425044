<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      d="M13.232 3.23233L16.768 6.76833M14.732 1.73233C15.2009 1.26343 15.8369 1 16.5 1C17.1631 1 17.7991 1.26343 18.268 1.73233C18.7369 2.20123 19.0003 2.8372 19.0003 3.50033C19.0003 4.16346 18.7369 4.79943 18.268 5.26833L4.5 19.0363H1V15.4643L14.732 1.73233Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
