<template>
  <svg
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      d="M1.91529 1.91529C1.32924 2.50134 1 3.2962 1 4.125V7.25C1 8.0788 1.32924 8.87366 1.91529 9.45971C2.50134 10.0458 3.2962 10.375 4.125 10.375H7.25C8.0788 10.375 8.87366 10.0458 9.45971 9.45971C10.0458 8.87366 10.375 8.0788 10.375 7.25V4.125C10.375 3.2962 10.0458 2.50134 9.45971 1.91529C8.87366 1.32924 8.0788 1 7.25 1H4.125C3.2962 1 2.50134 1.32924 1.91529 1.91529Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.5403 1.91529C16.9542 2.50134 16.625 3.2962 16.625 4.125V7.25C16.625 8.0788 16.9542 8.87366 17.5403 9.45971C18.1263 10.0458 18.9212 10.375 19.75 10.375H22.875C23.7038 10.375 24.4987 10.0458 25.0847 9.45971C25.6708 8.87366 26 8.0788 26 7.25V4.125C26 3.2962 25.6708 2.50134 25.0847 1.91529C24.4987 1.32924 23.7038 1 22.875 1H19.75C18.9212 1 18.1263 1.32924 17.5403 1.91529Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.91529 17.5403C1.32924 18.1263 1 18.9212 1 19.75V22.875C1 23.7038 1.32924 24.4987 1.91529 25.0847C2.50134 25.6708 3.2962 26 4.125 26H7.25C8.0788 26 8.87366 25.6708 9.45971 25.0847C10.0458 24.4987 10.375 23.7038 10.375 22.875V19.75C10.375 18.9212 10.0458 18.1263 9.45971 17.5403C8.87366 16.9542 8.0788 16.625 7.25 16.625H4.125C3.2962 16.625 2.50134 16.9542 1.91529 17.5403Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.5403 17.5403C16.9542 18.1263 16.625 18.9212 16.625 19.75V22.875C16.625 23.7038 16.9542 24.4987 17.5403 25.0847C18.1263 25.6708 18.9212 26 19.75 26H22.875C23.7038 26 24.4987 25.6708 25.0847 25.0847C25.6708 24.4987 26 23.7038 26 22.875V19.75C26 18.9212 25.6708 18.1263 25.0847 17.5403C24.4987 16.9542 23.7038 16.625 22.875 16.625H19.75C18.9212 16.625 18.1263 16.9542 17.5403 17.5403Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
