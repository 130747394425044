export function number(value) {
  if (isNaN(value)) {
    return 0;
  }
  return parseFloat(value);
}

export function numberWithCommas(value) {
  return number(value)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function numberWithCommasAndDecimal(value) {
  const decimalValue = number(value).toFixed(2);
  
  const newValue = decimalValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return newValue;
}
