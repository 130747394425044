<template>
  <svg
    width="70"
    height="78"
    viewBox="0 0 70 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      d="M18.3337 9.83333H10.0003C7.79019 9.83333 5.67057 10.7113 4.10777 12.2741C2.54497 13.8369 1.66699 15.9565 1.66699 18.1667V68.1667C1.66699 70.3768 2.54497 72.4964 4.10777 74.0592C5.67057 75.622 7.79019 76.5 10.0003 76.5H51.667C53.8771 76.5 55.9967 75.622 57.5595 74.0592C59.1223 72.4964 60.0003 70.3768 60.0003 68.1667V64M18.3337 9.83333C18.3337 12.0435 19.2116 14.1631 20.7744 15.7259C22.3372 17.2887 24.4569 18.1667 26.667 18.1667H35.0003C37.2105 18.1667 39.3301 17.2887 40.8929 15.7259C42.4557 14.1631 43.3337 12.0435 43.3337 9.83333M18.3337 9.83333C18.3337 7.6232 19.2116 5.50358 20.7744 3.94078C22.3372 2.37797 24.4569 1.5 26.667 1.5H35.0003C37.2105 1.5 39.3301 2.37797 40.8929 3.94078C42.4557 5.50358 43.3337 7.6232 43.3337 9.83333M43.3337 9.83333H51.667C53.8771 9.83333 55.9967 10.7113 57.5595 12.2741C59.1223 13.8369 60.0003 15.9565 60.0003 18.1667V30.6667M68.3336 47.3333H26.667M26.667 47.3333L39.167 34.8333M26.667 47.3333L39.167 59.8333"
      stroke-width="6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
