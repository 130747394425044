<template>
  <div class="checkbox-button flex cursor-pointer" @click="handleChange">
    <input type="checkbox" class="hidden" :name="name" :checked="inputValue" />
    <div class="relative p-4 border border-gray-300 rounded-md shadow">
      <check-circle-icon
        filled
        class="icon absolute right-4 inline-block h-6 w-6 text-blue-500"
      />
      <p class="mb-4 font-bold font-poppins">{{ label }}</p>
      <p class="text-gray-600 w-72">{{ description }}</p>
    </div>
  </div>
</template>

<script>
import { useField } from 'vee-validate';

export default {
  props: {
    label: {
      type: String,
      default: () => '',
    },
    description: {
      type: String,
      default: () => '',
    },
    name: {
      type: String,
      default: () => '',
    },
    value: {
      type: Boolean,
    },
    modelValue: {
      type: Boolean,
    },
  },
  setup(props) {
    const { value: inputValue } = useField(props.name, undefined, {
      initialValue: props.value,
    });

    function handleChange() {
      inputValue.value = !inputValue.value;
    }

    return {
      inputValue,
      handleChange,
    };
  },
};
</script>

<style lang="postcss" scoped>
.checkbox-button input:checked + div {
  @apply border-blue-300 transition-all ease-out duration-300;
}
.checkbox-button input + div .icon {
  @apply opacity-0 transition-all ease-out duration-300;
}
.checkbox-button input:checked + div .icon {
  @apply opacity-100;
}
</style>
