<template>
  <Field component="select" name="roleId" label="Role" :modelValue="-1">
    <template #default="{ readonly }">
      <option disabled selected :value="-1">Choose Role</option>
      <option
        v-for="(item, index) in roles"
        :disabled="readonly"
        :key="index"
        :value="item.id"
      >
        {{ item.title }}
      </option>
    </template>
  </Field>
</template>

<script>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useState } from '@/helpers/composables';

export default {
  setup() {
    const store = useStore();
    const [roles, setRoles] = useState([]);

    onMounted(async () => {
      const { data } = await store.dispatch('get', { action: '/roles' });
      setRoles(data);
    });

    return {
      roles,
    };
  },
};
</script>
