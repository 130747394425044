<template>
  <input
    type="checkbox"
    :value="modelValue"
    :checked="checked"
    :disabled="disabled"
    @input="
      (event) =>
        $emit('update', { value: modelValue, checked: event.target.checked })
    "
  />
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    checked: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ['update'],
};
</script>
