import { createApp } from 'vue';
import store from './store';
import router from './router';
import App from './App.vue';
import './index.css';

import overlays from './plugins/overlays';
import forms from './plugins/forms';
import elements from './plugins/elements';
import icons from './plugins/icons';

import clickOutside from './directives/click-outside';
import inputAutosize from './directives/input-autosize';

const app = createApp(App);

app.directive('click-outside', clickOutside);
app.directive('input-autosize', inputAutosize);

app
  .use(store)
  .use(router)
  .use(overlays)
  .use(forms)
  .use(elements)
  .use(icons)
  .use(toasts)
  .mount('#app');
