<template>
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      d="M15.8264 2.82917C15.2347 0.390278 11.7653 0.390278 11.1736 2.82917C10.7903 4.40556 8.98472 5.15278 7.60139 4.30833C5.45694 3.00278 3.00417 5.45694 4.30972 7.6C4.50598 7.92188 4.62345 8.28555 4.6526 8.66141C4.68174 9.03727 4.62172 9.4147 4.47742 9.76298C4.33313 10.1113 4.10864 10.4206 3.82222 10.6657C3.5358 10.9108 3.19555 11.0848 2.82917 11.1736C0.390278 11.7653 0.390278 15.2347 2.82917 15.8264C3.19523 15.9154 3.53512 16.0895 3.82121 16.3346C4.10731 16.5798 4.33153 16.8889 4.47566 17.237C4.61978 17.585 4.67975 17.9622 4.65067 18.3378C4.6216 18.7134 4.50431 19.0769 4.30833 19.3986C3.00278 21.5431 5.45694 23.9958 7.6 22.6903C7.92188 22.494 8.28555 22.3765 8.66141 22.3474C9.03727 22.3183 9.4147 22.3783 9.76298 22.5226C10.1113 22.6669 10.4206 22.8914 10.6657 23.1778C10.9108 23.4642 11.0848 23.8044 11.1736 24.1708C11.7653 26.6097 15.2347 26.6097 15.8264 24.1708C15.9154 23.8048 16.0895 23.4649 16.3346 23.1788C16.5798 22.8927 16.8889 22.6685 17.237 22.5243C17.585 22.3802 17.9622 22.3203 18.3378 22.3493C18.7134 22.3784 19.0769 22.4957 19.3986 22.6917C21.5431 23.9972 23.9958 21.5431 22.6903 19.4C22.494 19.0781 22.3765 18.7145 22.3474 18.3386C22.3183 17.9627 22.3783 17.5853 22.5226 17.237C22.6669 16.8887 22.8914 16.5794 23.1778 16.3343C23.4642 16.0892 23.8044 15.9152 24.1708 15.8264C26.6097 15.2347 26.6097 11.7653 24.1708 11.1736C23.8048 11.0846 23.4649 10.9105 23.1788 10.6653C22.8927 10.4202 22.6685 10.1111 22.5243 9.76303C22.3802 9.41496 22.3203 9.03779 22.3493 8.66218C22.3784 8.28658 22.4957 7.92313 22.6917 7.60139C23.9972 5.45694 21.5431 3.00417 19.4 4.30972C19.0781 4.50598 18.7145 4.62345 18.3386 4.6526C17.9627 4.68174 17.5853 4.62172 17.237 4.47742C16.8887 4.33313 16.5794 4.10864 16.3343 3.82222C16.0892 3.5358 15.9152 3.19555 15.8264 2.82917Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.446 16.446C17.2274 15.6646 17.6663 14.6047 17.6663 13.4997C17.6663 12.3946 17.2274 11.3348 16.446 10.5534C15.6646 9.77199 14.6047 9.33301 13.4997 9.33301C12.3946 9.33301 11.3348 9.77199 10.5534 10.5534C9.77199 11.3348 9.33301 12.3946 9.33301 13.4997C9.33301 14.6047 9.77199 15.6646 10.5534 16.446C11.3348 17.2274 12.3946 17.6663 13.4997 17.6663C14.6047 17.6663 15.6646 17.2274 16.446 16.446Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
