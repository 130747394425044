<template>
  <teleport to="#modals">
    <transition name="fade">
      <div class="backdrop" v-if="open">
        <div class="modal">
          <div
            class="flex justify-between items-center bg-yellow-500 px-6 py-4 rounded-t"
          >
            <slot name="header">
              <p class="text-xl font-semibold">{{ title }}</p>
            </slot>
            <button @click="close">
              <close-icon class="text-black w-4 h-4 stroke-2" />
            </button>
          </div>
          <div class="bg-white px-6 py-8 rounded-b">
            <div class="lg:px-12">
              <slot :close="close" :context="context" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { onMounted, onUnmounted, reactive } from 'vue';
import { useModal, useState } from '@/helpers/composables';

export default {
  props: {
    id: {
      type: String,
      default: () => '',
      required: true,
    },
    title: {
      type: String,
      default: () => '',
      required: true,
    },
    closeAction: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    const [open, setOpen] = useState(false);
    const context = reactive({});
    const { event } = useModal();

    onMounted(() => {
      event.on(`modal-${props.id}`, ({ open, options }) => {
        context.options = options;
        setOpen(open);
      });
    });

    onUnmounted(() => {
      event.off(`modal-${props.id}`);
    });

    function close() {
      if (props.closeAction) {
        return props.closeAction(() => {
          setOpen(false);
        });
      }
      setOpen(false);
    }

    return {
      open,
      close,
      context,
    };
  },
};
</script>

<style lang="postcss" scoped>
.backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  @apply fixed top-0 left-0 w-full h-full z-50;
}
.modal {
  @apply absolute 
    top-1/2 
    left-1/2
    transform
    -translate-x-1/2 
    -translate-y-1/2
    w-10/12
    max-w-2xl;
}
</style>