<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path
      d="M2.39961 4.8V3.6C2.39961 2.64522 2.77889 1.72955 3.45402 1.05442C4.12916 0.379285 5.04483 0 5.99961 0C6.95439 0 7.87006 0.379285 8.54519 1.05442C9.22032 1.72955 9.59961 2.64522 9.59961 3.6V4.8H10.1996C10.5179 4.8 10.8231 4.92643 11.0481 5.15147C11.2732 5.37652 11.3996 5.68174 11.3996 6V10.8C11.3996 11.1183 11.2732 11.4235 11.0481 11.6485C10.8231 11.8736 10.5179 12 10.1996 12H1.79961C1.48135 12 1.17612 11.8736 0.951081 11.6485C0.726038 11.4235 0.599609 11.1183 0.599609 10.8V6C0.599609 5.34 1.13961 4.8 1.79961 4.8H2.39961ZM5.39961 8.838V10.2H6.59961V8.838C6.82838 8.70592 7.00717 8.50204 7.10826 8.25799C7.20935 8.01394 7.22709 7.74335 7.15872 7.48819C7.09035 7.23303 6.9397 7.00756 6.73012 6.84675C6.52055 6.68593 6.26377 6.59877 5.99961 6.59877C5.73545 6.59877 5.47867 6.68593 5.2691 6.84675C5.05952 7.00756 4.90887 7.23303 4.8405 7.48819C4.77213 7.74335 4.78986 8.01394 4.89095 8.25799C4.99204 8.50204 5.17084 8.70592 5.39961 8.838ZM4.19961 3.6V4.8H7.79961V3.6C7.79961 3.12261 7.60997 2.66477 7.2724 2.32721C6.93484 1.98964 6.477 1.8 5.99961 1.8C5.52222 1.8 5.06438 1.98964 4.72682 2.32721C4.38925 2.66477 4.19961 3.12261 4.19961 3.6Z"
    />
  </svg>
</template>
