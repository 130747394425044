<template>
  <teleport to="#toasts">
    <div class="toasts">
      <transition-group name="slide-up">
        <div v-for="toast in toasts" :key="toast.id" class="slide-up toast">
          <div class="flex items-center justify-between">
            <check-circle-icon
              v-if="toast.type === 'success'"
              class="h-6 w-6 text-green-500"
            />
            <exclamation-circle-icon v-else class="h-6 w-6 text-red-500" />
            <div class="ml-2 mr-8">{{ toast.message }}</div>
            <button @click="remove(toast.id)">
              <close-icon class="text-gray-500 w-3 h-3 stroke-2" />
            </button>
          </div>
        </div>
      </transition-group>
    </div>
  </teleport>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue';
import { useToast } from '@/helpers/composables';

export default {
  setup() {
    const { event } = useToast();
    const toasts = ref([]);
    const timers = ref([]);

    function setTimer(options) {
      return window.setTimeout(remove, options.duration, options.id);
    }

    function add(options) {
      toasts.value.push(options);
      timers.value.push(setTimer(options));
    }

    function remove(id) {
      const index = toasts.value.findIndex((r) => r.id === id);
      if (!index !== -1) {
        toasts.value.splice(index, 1);
        timers.value.splice(index, 1);
      }
    }

    function toast(options) {
      const id = Date.now();
      add({ ...options, id });
    }

    onMounted(() => {
      event.on('toast', (options) => {
        toast(options);
      });
    });

    onUnmounted(() => {
      event.off('toast');
    });

    return {
      toasts,
      remove,
    };
  },
};
</script>

<style lang="postcss" scoped>
.toasts {
  @apply fixed
    z-30
    bottom-8
    right-8
    space-y-4;
}
.toast {
  @apply px-4
    py-2
    whitespace-pre-wrap
    font-poppins
    font-semibold
    text-lg
    text-gray-900
    bg-white
    border-2
    border-gray-300
    rounded-xl
    shadow;
}
</style>
