<template>
  <svg
    width="86"
    height="70"
    viewBox="0 0 86 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      d="M63.833 68.3336H84.6663V60.0003C84.6662 57.4025 83.8566 54.8694 82.3503 52.7529C80.8439 50.6365 78.7156 49.0419 76.2611 48.191C73.8067 47.34 71.1481 47.2748 68.6549 48.0046C66.1618 48.7343 63.9579 50.2227 62.3497 52.2628M63.833 68.3336H22.1663M63.833 68.3336V60.0003C63.833 57.267 63.308 54.6545 62.3497 52.2628M62.3497 52.2628C60.8024 48.396 58.132 45.0814 54.683 42.7467C51.234 40.4119 47.1646 39.1641 42.9997 39.1641C38.8348 39.1641 34.7653 40.4119 31.3164 42.7467C27.8674 45.0814 25.197 48.396 23.6497 52.2628M22.1663 68.3336H1.33301V60.0003C1.3332 57.4025 2.14274 54.8694 3.6491 52.7529C5.15546 50.6365 7.28378 49.0419 9.73822 48.191C12.1927 47.34 14.8512 47.2748 17.3444 48.0046C19.8376 48.7343 22.0415 50.2227 23.6497 52.2628M22.1663 68.3336V60.0003C22.1663 57.267 22.6913 54.6545 23.6497 52.2628M55.4997 14.167C55.4997 17.4822 54.1827 20.6616 51.8385 23.0058C49.4943 25.35 46.3149 26.667 42.9997 26.667C39.6845 26.667 36.505 25.35 34.1608 23.0058C31.8166 20.6616 30.4997 17.4822 30.4997 14.167C30.4997 10.8518 31.8166 7.67236 34.1608 5.32816C36.505 2.98395 39.6845 1.66699 42.9997 1.66699C46.3149 1.66699 49.4943 2.98395 51.8385 5.32816C54.1827 7.67236 55.4997 10.8518 55.4997 14.167ZM80.4997 26.667C80.4997 28.8771 79.6217 30.9967 78.0589 32.5595C76.4961 34.1223 74.3765 35.0003 72.1663 35.0003C69.9562 35.0003 67.8366 34.1223 66.2738 32.5595C64.711 30.9967 63.833 28.8771 63.833 26.667C63.833 24.4569 64.711 22.3372 66.2738 20.7744C67.8366 19.2116 69.9562 18.3337 72.1663 18.3337C74.3765 18.3337 76.4961 19.2116 78.0589 20.7744C79.6217 22.3372 80.4997 24.4569 80.4997 26.667ZM22.1663 26.667C22.1663 28.8771 21.2884 30.9967 19.7256 32.5595C18.1628 34.1223 16.0431 35.0003 13.833 35.0003C11.6229 35.0003 9.50325 34.1223 7.94045 32.5595C6.37765 30.9967 5.49967 28.8771 5.49967 26.667C5.49967 24.4569 6.37765 22.3372 7.94045 20.7744C9.50325 19.2116 11.6229 18.3337 13.833 18.3337C16.0431 18.3337 18.1628 19.2116 19.7256 20.7744C21.2884 22.3372 22.1663 24.4569 22.1663 26.667Z"
      stroke-width="6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
