<template>
  <svg
    width="25"
    height="27"
    viewBox="0 0 25 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      d="M16.2778 20.4444H23.2222L21.2708 18.4931C21.0088 18.2309 20.8009 17.9198 20.6591 17.5773C20.5173 17.2349 20.4444 16.8679 20.4444 16.4972V12.1111C20.4447 10.3874 19.9105 8.70603 18.9156 7.29846C17.9207 5.89088 16.5139 4.82634 14.8889 4.25139V3.77778C14.8889 3.04107 14.5962 2.33453 14.0753 1.81359C13.5544 1.29266 12.8478 1 12.1111 1C11.3744 1 10.6679 1.29266 10.1469 1.81359C9.62599 2.33453 9.33333 3.04107 9.33333 3.77778V4.25139C6.09722 5.39583 3.77778 8.48333 3.77778 12.1111V16.4986C3.77778 17.2458 3.48056 17.9639 2.95139 18.4931L1 20.4444H7.94444M16.2778 20.4444H7.94444M16.2778 20.4444V21.8333C16.2778 22.9384 15.8388 23.9982 15.0574 24.7796C14.276 25.561 13.2162 26 12.1111 26C11.006 26 9.94623 25.561 9.16483 24.7796C8.38343 23.9982 7.94444 22.9384 7.94444 21.8333V20.4444"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
