<template>
  <button
    :type="type"
    class="button rounded-md shadow"
    :class="[
      `button-${variant}`,
      { outline: outline },
      { disabled: disabled },
      { append: hasAppendSlot },
    ]"
    :disabled= "disabled"
  >
    <span class="button-label">
      <slot>
        {{ label }}
      </slot>
    </span>
    <span v-if="hasAppendSlot" class="append-slot">
      <slot name="append" />
    </span>
  </button>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    type: {
      type: String,
      default: () => 'submit',
    },
    label: {
      type: String,
      default: () => '',
    },
    variant: {
      type: String,
      default: () => 'primary',
    },
    outline: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
  },
  setup(props, { slots }) {
    const hasAppendSlot = computed(() => slots.append);

    return {
      hasAppendSlot,
    };
  },
};
</script>

<style lang="postcss" scoped>
.button {
  @apply font-poppins
    text-sm
    border
    border-transparent
    font-medium
    focus:outline-none
    transition-all
    ease-out
    duration-300;
}
.button.append {
  @apply flex items-center;
}
.button.disabled {
  @apply opacity-50 cursor-default;
}
.button-label {
  @apply py-2 px-4 block flex-1;
}
.button.append .button-label {
  @apply border-r border-yellow-600;
}
.button .append-slot {
  @apply px-4 py-2 block;
}
.button-primary {
  @apply bg-yellow-500
    hover:bg-yellow-400;
}
.button-secondary {
  @apply bg-black
    text-white
    hover:bg-gray-800;
}
.button-gray {
  @apply bg-gray-300
    text-gray-600;
}
.button-success {
  @apply bg-green-600
    hover:bg-green-500
    text-white;
}
.button-danger {
  @apply bg-red-600
    hover:bg-red-500
    text-white;
}
.button-blue {
  @apply bg-blue-600
    hover:bg-blue-500
    text-white;
}
.button-empty {
  @apply py-0
    bg-transparent
    text-gray-600
    border-gray-600
    shadow-none
    pointer-events-none;
}
.button-light.outline {
  @apply text-white border border-white bg-black bg-opacity-0 hover:bg-opacity-10;
}
.button-input {
  @apply text-gray-500 border-gray-300;
}
</style>
