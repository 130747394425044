<template>
  <svg
    width="27"
    height="25"
    viewBox="0 0 27 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      d="M20.4444 17.6667L26 12.1111M26 12.1111L20.4444 6.55556M26 12.1111H6.55556M14.8889 17.6667V19.0556C14.8889 20.1606 14.4499 21.2204 13.6685 22.0018C12.8871 22.7832 11.8273 23.2222 10.7222 23.2222H5.16667C4.0616 23.2222 3.00179 22.7832 2.22039 22.0018C1.43899 21.2204 1 20.1606 1 19.0556V5.16667C1 4.0616 1.43899 3.00179 2.22039 2.22039C3.00179 1.43899 4.0616 1 5.16667 1H10.7222C11.8273 1 12.8871 1.43899 13.6685 2.22039C14.4499 3.00179 14.8889 4.0616 14.8889 5.16667V6.55556"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
