<template>
  <span>
    <svg
      v-if="filled"
      viewBox="0 0 25 25"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5 22.5C15.1522 22.5 17.6957 21.4464 19.5711 19.5711C21.4464 17.6957 22.5 15.1522 22.5 12.5C22.5 9.84784 21.4464 7.3043 19.5711 5.42893C17.6957 3.55357 15.1522 2.5 12.5 2.5C9.84784 2.5 7.3043 3.55357 5.42893 5.42893C3.55357 7.3043 2.5 9.84784 2.5 12.5C2.5 15.1522 3.55357 17.6957 5.42893 19.5711C7.3043 21.4464 9.84784 22.5 12.5 22.5ZM17.1337 10.8837C17.3614 10.648 17.4874 10.3322 17.4846 10.0045C17.4817 9.67675 17.3503 9.36324 17.1185 9.13148C16.8868 8.89972 16.5732 8.76826 16.2455 8.76541C15.9178 8.76256 15.602 8.88855 15.3663 9.11625L11.25 13.2325L9.63375 11.6163C9.398 11.3886 9.08224 11.2626 8.7545 11.2654C8.42675 11.2683 8.11324 11.3997 7.88148 11.6315C7.64972 11.8632 7.51826 12.1768 7.51541 12.5045C7.51256 12.8322 7.63855 13.148 7.86625 13.3837L10.3663 15.8837C10.6007 16.1181 10.9185 16.2497 11.25 16.2497C11.5815 16.2497 11.8993 16.1181 12.1337 15.8837L17.1337 10.8837Z"
        fill="currentColor"
      />
    </svg>
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    filled: {
      type: Boolean,
    },
  },
  setup() {},
};
</script>
