<template>
  <div
    class="field"
    :class="{ inline: inline, highlight: highlight, readonly: readonly }"
  >
    <div
      :class="[
        inline ? 'grid grid-cols-2 items-center gap-8 relative' : wrapperClass,
      ]"
    >
      <slot name="label" :fieldName="name" :label="label">
        <label
          :for="name"
          class="label"
          :class="[{ 'font-poppins font-semibold': bold }, labelClass]"
          >{{ label }}</label
        >
      </slot>
      <div class="relative mt-1">
        <component
          :is="component"
          :id="name"
          :name="name"
          :type="type"
          :placeholder="placeholder"
          :value="inputValue"
          :class="{ 'has-error': !!errorMessage }"
          :readonly="readonly"
          class="input"
          @input="handleChange"
          @blur="handleBlur"
        >
          <slot :readonly="readonly" />
        </component>
      </div>
      <div class="append-slot" v-if="hasAppendSlot">
        <slot name="append" />
      </div>
    </div>
    <p class="error" v-show="errorMessage && meta.touched">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { computed, watch } from 'vue';
import { useField } from 'vee-validate';

export default {
  props: {
    component: {
      type: String,
      default: () => 'input',
    },
    name: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    modelValue: {
      type: [String, Number, Array],
      default: () => '',
    },
    type: {
      type: String,
      default: () => 'text',
    },
    readonly: {
      type: Boolean,
    },
    inline: {
      type: Boolean,
    },
    highlight: {
      type: Boolean,
    },
    autosize: {
      type: Boolean,
    },
    bold: {
      type: Boolean,
    },
    standalone: {
      type: Boolean,
    },
    wrapperClass: {
      type: String,
      default: () => '',
    },
    labelClass: {
      type: String,
      default: () => '',
    },
    transform: {
      type: Function,
      default: (value) => value,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { slots, emit }) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange: handleChangeValidate,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.modelValue,
      type: props.type,
      standalone: props.standalone,
    });

    const hasAppendSlot = computed(() => slots.append);

    watch(
      () => props.modelValue,
      (val) => {
        inputValue.value = val;
      }
    );

    const handleChange = (e) => {
      emit('update:modelValue', e.target.value);
      handleChangeValidate(e);
    };

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
      hasAppendSlot,
    };
  },
};
</script>

<style lang="postcss" scoped>
.field {
  @apply relative block;
}
.field.inline .label {
  @apply text-right;
}
.field.inline .error {
  @apply text-right;
}
.field.highlight .input,
.field.highlight.readonly .input {
  @apply bg-yellow-100;
}
.field.readonly .input {
  @apply bg-gray-100 cursor-default text-gray-700;
}
.field.readonly .input::placeholder {
  @apply text-gray-700;
}
.label {
  @apply block text-base font-medium;
}
.input {
  @apply block
      w-full
      px-3
      py-2
      border 
      rounded-md
      border-gray-300
      placeholder-gray-500
      focus:outline-none 
      focus:ring-yellow-500
      focus:border-yellow-500;
}
textarea.input {
  @apply resize-none;
}
.append-slot {
  @apply absolute 
    right-3 
    top-1/2
    transform
    -translate-y-1/2;
}
.has-error {
  @apply border-red-500
    focus:ring-red-500
    focus:border-red-500;
}

textarea {
  @apply h-32;
}
</style>
