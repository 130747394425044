<template>
  <svg
    width="16"
    height="8"
    viewBox="0 0 16 8"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0C5.48128 0 6.77364 0.805191 7.46476 2H14C14.1326 2 14.2598 2.05268 14.3536 2.14645L15.8536 3.64645C16.0488 3.84171 16.0488 4.15829 15.8536 4.35355L14.3536 5.85355C14.1583 6.04882 13.8417 6.04882 13.6464 5.85355L13 5.20711L12.3536 5.85355C12.1583 6.04882 11.8417 6.04882 11.6464 5.85355L11 5.20711L10.3536 5.85355C10.1583 6.04882 9.84171 6.04882 9.64645 5.85355L9 5.20711L8.35355 5.85355C8.25979 5.94732 8.13261 6 8 6H7.46476C6.77364 7.19481 5.48128 8 4 8C1.79086 8 0 6.20914 0 4ZM4 1C2.34315 1 1 2.34315 1 4C1 5.65685 2.34315 7 4 7C5.19599 7 6.22952 6.30016 6.71155 5.28545C6.79438 5.1111 6.97016 5 7.16318 5H7.79289L8.64645 4.14645C8.84171 3.95118 9.15829 3.95118 9.35355 4.14645L10 4.79289L10.6464 4.14645C10.8417 3.95118 11.1583 3.95118 11.3536 4.14645L12 4.79289L12.6464 4.14645C12.8417 3.95118 13.1583 3.95118 13.3536 4.14645L14 4.79289L14.7929 4L13.7929 3H7.16318C6.97016 3 6.79438 2.8889 6.71155 2.71455C6.22952 1.69984 5.19599 1 4 1Z"
    />
    <path
      d="M4 4C4 4.55228 3.55228 5 3 5C2.44772 5 2 4.55228 2 4C2 3.44772 2.44772 3 3 3C3.55228 3 4 3.44772 4 4Z"
    />
  </svg>
</template>
