<template>
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.70369 15C4.5036 15 1.09875 11.866 1.09875 8C1.09875 4.13401 4.5036 1 8.70369 1C12.9038 1 16.3086 4.13401 16.3086 8C16.3086 11.866 12.9038 15 8.70369 15ZM8.70369 16C13.5038 16 17.395 12.4183 17.395 8C17.395 3.58172 13.5038 0 8.70369 0C3.90358 0 0.0123291 3.58172 0.0123291 8C0.0123291 12.4183 3.90358 16 8.70369 16Z"
    />
    <path
      d="M8.70369 4C9.00369 4 9.2469 4.22386 9.2469 4.5V7.5H12.5062C12.8062 7.5 13.0494 7.72386 13.0494 8C13.0494 8.27614 12.8062 8.5 12.5062 8.5H9.2469V11.5C9.2469 11.7761 9.00369 12 8.70369 12C8.40368 12 8.16048 11.7761 8.16048 11.5V8.5H4.90122C4.60121 8.5 4.35801 8.27614 4.35801 8C4.35801 7.72386 4.60121 7.5 4.90122 7.5H8.16048V4.5C8.16048 4.22386 8.40368 4 8.70369 4Z"
    />
  </svg>
</template>
