<template>
  <svg
    width="8"
    height="16"
    viewBox="0 0 8 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.27237 14.8182C3.27237 15.1947 3.57763 15.5 3.95419 15.5C4.33075 15.5 4.63601 15.1947 4.63601 14.8182L4.63601 2.82787L6.19935 4.39121C6.46561 4.65748 6.89732 4.65748 7.16358 4.39121C7.42985 4.12494 7.42985 3.69324 7.16358 3.42697L4.43746 0.700852L4.42733 0.690881C4.36408 0.629877 4.29194 0.583495 4.21518 0.551735C4.13479 0.518399 4.04664 0.5 3.95419 0.5C3.86175 0.5 3.7736 0.518399 3.6932 0.551735C3.61279 0.585007 3.53744 0.634329 3.47207 0.6997L0.744802 3.42697C0.478535 3.69324 0.478535 4.12494 0.744802 4.39121C1.01107 4.65748 1.44277 4.65747 1.70904 4.39121L3.27238 2.82787L3.27237 14.8182Z"
    />
  </svg>
</template>
