<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      d="M10.4167 6.25V14.5833V6.25ZM6.25 10.4167H14.5833H6.25ZM12.5 35.4167V43.75V35.4167ZM8.33333 39.5833H16.6667H8.33333ZM27.0833 6.25L31.8458 20.5354L43.75 25L31.8458 29.4646L27.0833 43.75L22.3208 29.4646L10.4167 25L22.3208 20.5354L27.0833 6.25Z"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
