<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.6665 12.6665V13.5832C1.6665 14.3125 1.95624 15.012 2.47196 15.5277C2.98769 16.0434 3.68716 16.3332 4.4165 16.3332H13.5832C14.3125 16.3332 15.012 16.0434 15.5277 15.5277C16.0434 15.012 16.3332 14.3125 16.3332 13.5832V12.6665M12.6665 5.33317L8.99984 1.6665M8.99984 1.6665L5.33317 5.33317M8.99984 1.6665V12.6665"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
