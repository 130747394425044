import Modal from '@/components/overlays/modals/base/Modal.vue';
import ConfirmModal from '@/components/overlays/modals/base/ConfirmModal.vue';
import Toast from '@/components/overlays/toasts/Toast.vue';

export default {
  install(app) {
    app.component('Modal', Modal);
    app.component('ConfirmModal', ConfirmModal);
    app.component('Toast', Toast);
  },
};
