<template>
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      d="M3.94583 21.5611C6.8603 19.9138 10.1522 19.0505 13.5 19.0556C16.9722 19.0556 20.2319 19.9653 23.0542 21.5611M17.6667 10.7222C17.6667 11.8273 17.2277 12.8871 16.4463 13.6685C15.6649 14.4499 14.6051 14.8889 13.5 14.8889C12.3949 14.8889 11.3351 14.4499 10.5537 13.6685C9.77232 12.8871 9.33333 11.8273 9.33333 10.7222C9.33333 9.61715 9.77232 8.55734 10.5537 7.77594C11.3351 6.99454 12.3949 6.55556 13.5 6.55556C14.6051 6.55556 15.6649 6.99454 16.4463 7.77594C17.2277 8.55734 17.6667 9.61715 17.6667 10.7222ZM26 13.5C26 15.1415 25.6767 16.767 25.0485 18.2835C24.4203 19.8001 23.4996 21.1781 22.3388 22.3388C21.1781 23.4996 19.8001 24.4203 18.2835 25.0485C16.767 25.6767 15.1415 26 13.5 26C11.8585 26 10.233 25.6767 8.71646 25.0485C7.19989 24.4203 5.8219 23.4996 4.66116 22.3388C3.50043 21.1781 2.57969 19.8001 1.95151 18.2835C1.32332 16.767 1 15.1415 1 13.5C1 10.1848 2.31696 7.00537 4.66116 4.66117C7.00537 2.31696 10.1848 1 13.5 1C16.8152 1 19.9946 2.31696 22.3388 4.66117C24.683 7.00537 26 10.1848 26 13.5Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
