<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      d="M25 16.6667C21.5479 16.6667 18.75 18.5312 18.75 20.8333C18.75 23.1354 21.5479 25 25 25C28.4521 25 31.25 26.8646 31.25 29.1667C31.25 31.4687 28.4521 33.3333 25 33.3333M25 16.6667V33.3333M25 16.6667C27.3125 16.6667 29.3333 17.5042 30.4146 18.75M25 16.6667V14.5833M25 33.3333V35.4167M25 33.3333C22.6875 33.3333 20.6667 32.4958 19.5854 31.25M43.75 25C43.75 27.4623 43.265 29.9005 42.3227 32.1753C41.3805 34.4502 39.9993 36.5172 38.2582 38.2582C36.5172 39.9993 34.4502 41.3805 32.1753 42.3227C29.9005 43.265 27.4623 43.75 25 43.75C22.5377 43.75 20.0995 43.265 17.8247 42.3227C15.5498 41.3805 13.4828 39.9993 11.7417 38.2582C10.0006 36.5172 8.61953 34.4502 7.67726 32.1753C6.73498 29.9005 6.25 27.4623 6.25 25C6.25 20.0272 8.22544 15.2581 11.7417 11.7417C15.2581 8.22544 20.0272 6.25 25 6.25C29.9728 6.25 34.7419 8.22544 38.2582 11.7417C41.7746 15.2581 43.75 20.0272 43.75 25Z"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
