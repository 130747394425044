<template>
  <svg
    viewBox="0 0 22 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      d="M6.55556 13.5H14.8889M6.55556 19.0556H14.8889M17.6667 26H3.77778C3.04107 26 2.33453 25.7073 1.81359 25.1864C1.29266 24.6655 1 23.9589 1 23.2222V3.77778C1 3.04107 1.29266 2.33453 1.81359 1.81359C2.33453 1.29266 3.04107 1 3.77778 1H11.5361C11.9044 1.00008 12.2576 1.14646 12.5181 1.40694L20.0375 8.92639C20.298 9.18679 20.4444 9.54001 20.4444 9.90833V23.2222C20.4444 23.9589 20.1518 24.6655 19.6309 25.1864C19.1099 25.7073 18.4034 26 17.6667 26Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
