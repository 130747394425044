<template>
  <form @submit="onSubmit">
    <slot :meta="meta" :setFieldValue="setFieldValue" :onSubmit="onSubmit" />
    <transition name="fade">
      <slot name="success">
        <div v-if="feedback && successMessage" class="success">
          {{ successMessage }}
        </div>
      </slot>
    </transition>
    <transition name="fade">
      <slot name="error">
        <div v-if="feedback">
          <div
            v-for="(error, index) in errorMessages"
            :key="index"
            class="error"
          >
            {{ error.message }}
          </div>
        </div>
      </slot>
    </transition>
  </form>
</template>

<script>
import { onMounted, onUnmounted, computed } from 'vue';
import { useForm } from 'vee-validate';
import { useStore } from 'vuex';
import { useState, useFormInvoke } from '@/helpers/composables';

export default {
  props: {
    id: {
      type: String,
      default: () => '',
    },
    dispatch: {
      type: String,
      default: () => '',
    },
    action: {
      type: String,
      default: () => '',
    },
    method: {
      type: String,
      default: () => 'post',
    },
    validationSchema: {
      type: Object,
      default: () => {},
    },
    initialValues: {
      type: Object,
      default: () => {},
    },
    param: {
      type: [String, Number],
      default: () => '',
    },
    feedback: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: ['success', 'error'],
  setup(props, { emit }) {
    const store = useStore();

    const { handleSubmit, meta, setFieldValue } = useForm({
      initialValues: computed(() => props.initialValues),
      validationSchema: props.validationSchema,
    });

    const { event } = useFormInvoke(props.id);
    const [errorMessages, setErrorMessages] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');

    onMounted(() => {
      event.on(`form-${props.id}`, () => {
        onSubmit();
      });
    });

    onUnmounted(() => {
      event.off(`form-${props.id}`);
    });

    const onSubmit = handleSubmit(
      async (formData) => {
        try {
          const { method, action, param, dispatch } = props;
          const dispatchAction = dispatch ? dispatch : method;
          const { data, message } = await store.dispatch(dispatchAction, {
            action,
            formData,
            param,
          });
          //setSuccessMessage(message);
          setErrorMessages([]);
          emit('success', { data, message });
        } catch (err) {
          if(err.response){
            const error = err.response.data;
            const errors = error.details ? [...error.details] : [error];
            setErrorMessages(errors);
            setSuccessMessage('');
            emit('error', { errors });
          }
          
        }
      },
      (err) => {
        const errors = Object.values(err.errors).map((e) => {
          return { message: e };
        });
        setErrorMessages(errors);
        setSuccessMessage('');
        emit('error', { errors });
      }
    );

    return {
      onSubmit,
      meta,
      errorMessages,
      successMessage,
      setFieldValue,
    };
  },
};
</script>
