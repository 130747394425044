const state = () => {
  return {
    notifications: [],
  };
};

const getters = {
  //
};

const mutations = {
  notifications(state, notifications) {
    state.notifications = notifications;
  },
};

const actions = {
  async getNotifications({ dispatch, commit }){
    const { data } = await dispatch(
      'get',
      { action: '/notifications/user?scope[read]=0'},
      { root: true }
    );
    commit('notifications', data);
    return { data };
  }
};

export default { namespaced: true, state, getters, mutations, actions };
