import router from '../router';

const state = () => {
  return {
    user: null,
    permissions: [],
  };
};

const getters = {
  isAuthenticated(state) {
    return state.user ? true : false;
  },
  fullName({ user }) {
    return user ? `${user.firstName} ${user.lastName}` : null;
  },
};

const mutations = {
  login(state, user) {
    state.user = user;
  },
  logout(state) {
    state.user = null;
    state.permissions = [];
  },
  permissions(state, permissions) {
    state.permissions = permissions;
  },
};

const actions = {
  async initialize({ dispatch, commit }) {
    const { data } = await dispatch(
      'post',
      { action: '/session' },
      { root: true }
    );
    if (data) {
      await dispatch('permissions', { user: data });
      commit('login', data);
      if (localStorage.getItem('page')) {
        const { name, params } = JSON.parse(localStorage.getItem('page'));
        return router.push({ name, params });
      }
      router.push({ name: 'dashboard' });
    }
  },
  async login({ commit, dispatch }, { formData }) {
    const { data, message } = await dispatch(
      'post',
      { action: '/login', formData },
      { root: true }
    );
    if (data) {
      await dispatch('permissions', { user: data });
      commit('login', data);
      router.push({ name: 'dashboard' });
      return { data, message };
    }
  },
  async register({ dispatch }, { formData }) {
    const { message } = await dispatch(
      'post',
      { action: '/register', formData },
      { root: true }
    );
    if (message) {
      router.push({ name: 'login' });
    }
  },
  async proxy({ dispatch, commit }, { formData = {}, method = 'post' }) {
    const { data, message } = await dispatch(
      method,
      { action: '/proxy', formData },
      { root: true }
    );
    if (data && message) {
      await dispatch('permissions', { user: data });
      commit('login', data);
      return { data, message };
    }
  },
  async logout({ dispatch }) {
    const { message } = await dispatch(
      'post',
      { action: '/logout' },
      { root: true }
    );
    if (message) {
      dispatch('logoutWithoutAction');
    }
  },
  logoutWithoutAction({ commit }) {
    commit('logout');
    localStorage.removeItem('page');
    router.push({ name: 'login' });
  },
  forgotPassword({ dispatch }, { formData }) {
    return dispatch(
      'post',
      { action: '/forgot-password', formData },
      { root: true }
    );
  },
  resetPassword({ dispatch }, { formData }) {
    return dispatch(
      'post',
      { action: '/reset-password', formData },
      { root: true }
    );
  },
  updatePassword({ dispatch }, { formData }) {
    return dispatch(
      'put',
      { action: '/update-password', formData },
      { root: true }
    );
  },
  async permissions({ commit, dispatch }, { user }) {
    const { data } = await dispatch(
      'get',
      { action: `/permissions/role/${user.roleId}` },
      { root: true }
    );
    if (data) {
      commit('permissions', data);
    }
  },
};

export default { namespaced: true, state, getters, mutations, actions };
