<template>
  <svg
    width="27"
    height="22"
    viewBox="0 0 27 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      d="M3.77778 20.4444C3.04107 20.4444 2.33453 20.1518 1.81359 19.6309C1.29266 19.1099 1 18.4034 1 17.6667V3.77778C1 3.04107 1.29266 2.33453 1.81359 1.81359C2.33453 1.29266 3.04107 1 3.77778 1H9.33333L12.1111 3.77778H17.6667C18.4034 3.77778 19.1099 4.07044 19.6309 4.59137C20.1518 5.1123 20.4444 5.81884 20.4444 6.55556V7.94444M3.77778 20.4444H23.2222C23.9589 20.4444 24.6655 20.1518 25.1864 19.6309C25.7073 19.1099 26 18.4034 26 17.6667V10.7222C26 9.98551 25.7073 9.27897 25.1864 8.75804C24.6655 8.2371 23.9589 7.94444 23.2222 7.94444H9.33333C8.59662 7.94444 7.89008 8.2371 7.36915 8.75804C6.84821 9.27897 6.55556 9.98551 6.55556 10.7222V17.6667C6.55556 18.4034 6.2629 19.1099 5.74196 19.6309C5.22103 20.1518 4.51449 20.4444 3.77778 20.4444Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
