import { readonly, ref, computed } from 'vue';
import { useStore } from 'vuex';
import mitt from 'mitt';
import { DateTime } from 'luxon';
import * as qs from 'qs';
import { numberWithCommas } from './math';

const event = mitt();

export function useModal(modalId) {
  const open = (modalId) => (options) => {
    event.emit(`modal-${modalId}`, { open: true, options });
  };
  const close = (modalId) => (options) => {
    event.emit(`modal-${modalId}`, { open: false, options });
  };
  return { event, open: open(modalId), close: close(modalId) };
}

export function useFormInvoke(formId) {
  const submit = (formId) => () => {
    event.emit(`form-${formId}`);
  };
  return { event, submit: submit(formId) };
}

export function useToast() {
  const show = (message, type = 'success', duration = 3000) => {
    event.emit('toast', { message, type, duration });
  };
  return { event, show };
}

export function useTable() {
  const resetSelected = (id) => {
    event.emit('resetSelected', { id });
  };
  return { event, resetSelected };
}

export function useState(initialState) {
  const state = ref(initialState);
  const setState = (newState) => {
    state.value = newState;
  };

  return [readonly(state), setState];
}

export function useIcon(type) {
  const icons = {
    awaiting_accounting_approval: {
      name: 'exclamation-circle-icon',
      color: 'text-red-500',
    },
    awaiting_director_approval: {
      name: 'exclamation-circle-icon',
      color: 'text-red-500',
    },
    needs_revision: {
      name: 'thumb-down-icon',
      color: 'text-red-500',
    },
    approved: {
      name: 'thumb-up-icon',
      color: 'text-green-500',
    },
    sent_to_client: {
      name: 'check-circle-icon',
      color: 'text-green-500',
    },
  };

  return computed(() => icons[type]);
}

export function useAllow(permissions, resource, action) {
  return permissions.find(
    (r) => r.resource === resource && r.action === action && r.active
  );
}

export const useNumberWithCommas = (obj, key) =>
  computed({
    get: () => {
      return numberWithCommas(obj[key]);
    },
    set: (val) => {
      obj[key] = parseFloat(val.replace(',', ''));
    },
  });

export const useLocalDateTime = (value, format = 'MM/dd/yyyy') => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  return DateTime.fromISO(value).setZone(timeZone).toFormat(format);
};

export function useFetch(url, query) {
  const store = useStore();
  const currentUrl = ref(url);
  const currentQuery = ref(query);
  const data = ref();
  const pagination = ref();
  const params = new URLSearchParams();

  const fetch = async (url, query) => {
    currentUrl.value = url ? url : currentUrl.value;
    currentQuery.value = query ? query : currentQuery.value;

    try {
      const { data: responseData, pagination: responsePagination } =
        await store.dispatch('get', {
          action: currentUrl.value,
          query: currentQuery.value,
        });

      data.value = responseData;
      pagination.value = responsePagination;
      currentQuery.value = responsePagination && responsePagination.query;

      return { data: responseData, pagination: responsePagination };
    } catch (error) {
      console.error(error);
    }
  };

  const refresh = async () => {
    return await fetch(currentUrl.value, currentQuery.value);
  };

  const handleSort = ({ name, order }) => {
    if (order) {
      sort(name, order);
    } else {
      deleteQueryParam('order');
      fetch();
    }
  };

  const handleFilter = ({ name: field, value }) => {
    return filter(field, value);
  };

  const handleSearch = async (scope, value) => {
    setSearch(scope, value);
    return await fetch();
  };

  const filters = async (list) => {
    list.forEach((e) => {
      setFilter(e.name, e.value);
    });
    return await fetch();
  };

  const filter = async (field, value) => {
    setFilter(field, value);
    return await fetch();
  };

  const sort = async (field, order) => {
    setSort(field, order);
    return await fetch();
  };

  const setSearch = (scope, value) => {
    setQueryParam(`scope[${scope}]`, value);
  };

  const setFilter = (field, value) => {
    setQueryParam(`filter[${field}]`, value);
  };

  const setSort = (field, order) => {
    setQueryParam('order[]', `${field},${order}`);
  };

  const mergeQueryParams = (key, search) => {
    const {
      links: { base },
      query,
    } = pagination.value;
    const q = { ...query };
    delete q[key];
    const queryString = qs.stringify(q, { arrayFormat: 'brackets' });
    const params = new URLSearchParams(queryString);
    Object.entries(search).forEach((e) => {
      params.append(e[0], e[1]);
    });
    setUrl(base, params);
  };

  const setQueryParam = (key, value) => {
    const {
      links: { base },
    } = pagination.value;
    if (value) {
      params.set(key, value);
    } else {
      params.delete(key);
    }
    setUrl(base, params);
  };

  const deleteQueryParam = (key) => {
    const {
      links: { base },
      query,
    } = pagination.value;
    const q = { ...query };
    delete q[key];
    const queryString = qs.stringify(q, { arrayFormat: 'brackets' });
    const params = new URLSearchParams(queryString);
    setUrl(base, params);
  };

  const setUrl = (base, params) => {
    base = base.replace(process.env.VUE_APP_API_URL, '');
    currentUrl.value = `${base}?${decodeURIComponent(params.toString())}`;
  };

  const getQueryString = () =>  {
    const queryString = qs.stringify(currentQuery.value, { arrayFormat: 'brackets' });
    const params = new URLSearchParams(queryString);
    return decodeURIComponent(params.toString());
  };

  return {
    url: currentUrl,
    query: currentQuery,
    data,
    pagination,
    fetch,
    handleSearch,
    setSearch,
    filters,
    filter,
    sort,
    setFilter,
    setSort,
    mergeQueryParams,
    setQueryParam,
    deleteQueryParam,
    setUrl,
    handleSort,
    handleFilter,
    refresh,
    getQueryString
  };
}
