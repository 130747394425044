import ExclamationCircleIcon from '@/icons/ExclamationCircleIcon.vue';
import ThumbUpIcon from '@/icons/ThumbUpIcon.vue';
import ThumbDownIcon from '@/icons/ThumbDownIcon.vue';
import CheckCircleIcon from '@/icons/CheckCircleIcon.vue';
import DashboardIcon from '@/icons/DashboardIcon.vue';
import InvoicesIcon from '@/icons/InvoicesIcon.vue';
import FoldersIcon from '@/icons/FoldersIcon.vue';
import SettingsIcon from '@/icons/SettingsIcon.vue';
import AccountIcon from '@/icons/AccountIcon.vue';
import PresetsIcon from '@/icons/PresetsIcon.vue';
import UsersIcon from '@/icons/UsersIcon.vue';
import PermissionsIcon from '@/icons/PermissionsIcon.vue';
import NotificationsIcon from '@/icons/NotificationsIcon.vue';
import LogoutIcon from '@/icons/LogoutIcon.vue';
import SparklesIcon from '@/icons/SparklesIcon.vue';
import CurrencyDollarIcon from '@/icons/CurrencyDollarIcon.vue';
import CloseIcon from '@/icons/CloseIcon.vue';
import ChevronRightIcon from '@/icons/ChevronRightIcon.vue';
import LockIcon from '@/icons/LockIcon.vue';
import EditIcon from '@/icons/EditIcon.vue';
import DeleteIcon from '@/icons/DeleteIcon.vue';
import PlusIcon from '@/icons/PlusIcon.vue';
import SendIcon from '@/icons/SendIcon.vue';
import EllipsisIcon from '@/icons/EllipsisIcon.vue';
import UpArrowIcon from '@/icons/UpArrowIcon.vue';
import DownArrowIcon from '@/icons/DownArrowIcon.vue';
import AngleDownIcon from '@/icons/AngleDownIcon.vue';
import ChevronDownIcon from '@/icons/ChevronDownIcon.vue';
import SearchIcon from '@/icons/SearchIcon.vue';
import RightArrowIcon from '@/icons/RightArrowIcon.vue';
import PlusCircleIcon from '@/icons/PlusCircleIcon.vue';
import KeyIcon from '@/icons/KeyIcon.vue';
import UploadIcon from '@/icons/UploadIcon.vue';
import ExternalLinkIcon from '@/icons/ExternalLinkIcon.vue';

export default {
  install(app) {
    app.component('exclamation-circle-icon', ExclamationCircleIcon);
    app.component('thumb-down-icon', ThumbDownIcon);
    app.component('thumb-up-icon', ThumbUpIcon);
    app.component('check-circle-icon', CheckCircleIcon);
    app.component('dashboard-icon', DashboardIcon);
    app.component('invoices-icon', InvoicesIcon);
    app.component('folders-icon', FoldersIcon);
    app.component('settings-icon', SettingsIcon);
    app.component('account-icon', AccountIcon);
    app.component('presets-icon', PresetsIcon);
    app.component('users-icon', UsersIcon);
    app.component('permissions-icon', PermissionsIcon);
    app.component('notifications-icon', NotificationsIcon);
    app.component('logout-icon', LogoutIcon);
    app.component('sparkles-icon', SparklesIcon);
    app.component('currency-dollar-icon', CurrencyDollarIcon);
    app.component('close-icon', CloseIcon);
    app.component('chevron-right-icon', ChevronRightIcon);
    app.component('lock-icon', LockIcon);
    app.component('edit-icon', EditIcon);
    app.component('delete-icon', DeleteIcon);
    app.component('plus-icon', PlusIcon);
    app.component('send-icon', SendIcon);
    app.component('ellipsis-icon', EllipsisIcon);
    app.component('up-arrow-icon', UpArrowIcon);
    app.component('down-arrow-icon', DownArrowIcon);
    app.component('angle-down-icon', AngleDownIcon);
    app.component('chevron-down-icon', ChevronDownIcon);
    app.component('search-icon', SearchIcon);
    app.component('right-arrow-icon', RightArrowIcon);
    app.component('plus-circle-icon', PlusCircleIcon);
    app.component('key-icon', KeyIcon);
    app.component('upload-icon', UploadIcon);
    app.component('external-link-icon', ExternalLinkIcon);
  },
};
