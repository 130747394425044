<template>
  <svg
    width="7"
    height="16"
    viewBox="0 0 7 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.09091 1.18182C4.09091 0.80526 3.78565 0.5 3.40909 0.5C3.03253 0.5 2.72727 0.80526 2.72727 1.18182L2.72727 13.1721L1.16394 11.6088C0.89767 11.3425 0.465966 11.3425 0.1997 11.6088C-0.0665667 11.8751 -0.0665667 12.3068 0.1997 12.573L2.92582 15.2991L2.93595 15.3091C2.99921 15.3701 3.07134 15.4165 3.1481 15.4483C3.22849 15.4816 3.31664 15.5 3.40909 15.5C3.50154 15.5 3.58969 15.4816 3.67008 15.4483C3.7505 15.415 3.82584 15.3657 3.89121 15.3003L6.61848 12.573C6.88475 12.3068 6.88475 11.8751 6.61848 11.6088C6.35221 11.3425 5.92051 11.3425 5.65424 11.6088L4.09091 13.1721L4.09091 1.18182Z"
    />
  </svg>
</template>
