<template>
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      d="M1 1L19 19M1 19L19 1L1 19Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
